export const leaveRideshareArgs = ($t, date) => ({
  title: $t('CarpoolingBookingListView.FeedbackModalComponent.join_ride_share.title'),
  description: $t('CarpoolingBookingListView.FeedbackModalComponent.join_ride_share.description', { date }),
  secondaryCallToActionText: $t('buttons.no_go_back'),
  primaryCallToActionText: $t('CarpoolingBookingListView.FeedbackModalComponent.join_ride_share.primary_button_text'),
});

export const cancelRideshareArgs = ($t, date) => ({
  title: $t('CarpoolingBookingListView.FeedbackModalComponent.cancel_ride_share.title'),
  description: $t('CarpoolingBookingListView.FeedbackModalComponent.cancel_ride_share.description', { date }),
  secondaryCallToActionText: $t('buttons.no_go_back'),
  primaryCallToActionText: $t('buttons.confirm_cancel_action'),
});
