import { THEME_BUTTON_TYPES } from '@/composable/Theme/constants/specs';

/**
 * Return button color style according user status regarding that rideshare
 * @param {Boolean} isDriver
 * @param {Boolean} joined
 * @return {String}
 */
export const buttonColor = (isDriver, joined) => ((isDriver || joined)
  ? THEME_BUTTON_TYPES.DESTRUCTIVE
  : THEME_BUTTON_TYPES.PRIMARY);

/**
 * Return button text content according user status regarding that rideshare
 * @param {Boolean} isDriver
 * @param {Boolean} joined
 * @return {String}
 */
export const buttonText = ($t, isDriver, joined) => {
  if (isDriver) {
    return $t('CarpoolingBookingListView.CarpoolingRideshareComponent.action_button.cancel_rideshare');
  }
  return joined
    ? $t('CarpoolingBookingListView.CarpoolingRideshareComponent.action_button.leave_rideshare')
    : $t('CarpoolingBookingListView.CarpoolingRideshareComponent.action_button.join_rideshare');
};

/**
 * Return button action to emit according user status regarding that rideshare
 * @param {Boolean} isDriver
 * @param {Boolean} joined
 * @return {String}
 */
export const buttonAction = (isDriver, joined) => {
  if (isDriver) {
    return 'cancel-rideshare';
  }
  return joined
    ? 'leave-rideshare'
    : 'join-rideshare';
};

/**
 * Return button enable status according user status regarding that rideshare
 * @param {Number} availableSeats
 * @param {Boolean} joined
 * @return {Boolean}
 */
export const buttonDisabled = (availableSeats, joined) => availableSeats <= 0 && !joined;
