import { useTranslations } from '@/composable/App/Translations';

import businessProfileRoutesNames from '@Profile/Business/router/routes-names';

import CostAllocationTab from '@/components/Profile/tabs/CostAllocations/CostAllocationTab';
import PaymentMethodsTab from '@Shared/Payments/PaymentMethodsTab';

const { $t } = useTranslations();

export const computeBusinessProfileTabs = ({
  canSeeCostAllocationsTab,
  canSeePaymentMethodsTab,
}) => [
  {
    id: 'payment_methods',
    label: $t('views.business_profile.payment_label'),
    content: [
      {
        component: PaymentMethodsTab,
      },
    ],
    url: businessProfileRoutesNames.paymentMethods,
    isVisible: canSeePaymentMethodsTab,
  },
  {
    id: 'cost_allocation',
    label: $t('views.business_profile.cost_allocation_tab.label'),
    content: [
      {
        component: CostAllocationTab,
      },
    ],
    url: businessProfileRoutesNames.costAllocations,
    isVisible: canSeeCostAllocationsTab,
  },
];
