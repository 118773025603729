<template>
  <MainViewLayout>
    <template slot="main-content">
      <div class="phLayoutSettings LayoutPage">
        <div class="GenericTabs GenericTabs--Business">
          <MuiTabs
            :active="currentTabIndex"
            :tabs="visibleTabs"
            class="TabsContent pt-4"
            @active-tab="goToTab"
          />
        </div>
      </div>
    </template>
  </MainViewLayout>
</template>
<script>
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';

import { MuiTabs } from '@emobg/vue-base';
import { navigationErrorHandler } from '@emobg/web-utils';

import MainViewLayout from '@/templates/MainLayout/MainLayout';
import { canChangeCostAllocation, userData } from '@/stores/User/UserData/UserDataMapper';
import { setLoaderStatus } from '@Shared/store/Loader/LoaderMapper';
import { hasBusinessProfile, isPersonalProfileActive, setCurrentProfile } from '@/stores/User/Profile/ProfileMapper';

import businessProfileRoutesNames from '@Profile/Business/router/routes-names';
import personalProfileRoutesNames from '@Profile/Personal/router/routes-names';
import { computeBusinessProfileTabs } from '@Profile/Business/helpers/businessProfileHelpers';

export default {
  name: 'BusinessProfile',
  components: {
    MuiTabs,
    MainViewLayout,
  },

  provide() {
    return {
      usingCompanyAdmin: false,
    };
  },

  computed: {
    canChangeCostAllocation,
    isPersonalProfileActive,
    hasBusinessProfile,
    userData,
    currentTabIndex() {
      return findIndex(this.visibleTabs, { url: this.$route.name });
    },
  },
  watch: {
    isPersonalProfileActive(isActive) {
      if (isActive) {
        this.goToTab(personalProfileRoutesNames.personalProfile);
      }
    },
  },
  created() {
    this.TABS = computeBusinessProfileTabs({
      canSeeCostAllocationsTab: this.canChangeCostAllocation,
      canSeePaymentMethodsTab: this.isPaymentMethodsTabVisible(),
    });
    this.visibleTabs = filter(this.TABS, 'isVisible');
    this.businessProfileRoutes = { ...businessProfileRoutesNames };
    // Redirect to Personal information when user can not
    // see the payment methods tab (because he is visiting the
    // payment methods tab from his Business profile)
    if (this.isActiveRoute(this.businessProfileRoutes.paymentMethods)) {
      this.redirectWhenTabIsHidden();
    }
    // Set current profile to Business
    if (this.hasBusinessProfile) {
      this.setCurrentProfile(this.hasBusinessProfile);
    } else {
      this.$router.replace({ name: personalProfileRoutesNames.personalProfile });
    }
  },
  mounted() {
    this.setLoaderStatus(false);
  },
  methods: {
    setCurrentProfile,
    setLoaderStatus,
    goToTab(routeName) {
      this.$router.push({ name: routeName }).catch(navigationErrorHandler);
    },
    isActiveRoute(routeName) {
      return this.$route.name === routeName;
    },
    redirectWhenTabIsHidden() {
      if (!this.isPaymentMethodsTabVisible()) {
        this.$router.replace({ name: businessProfileRoutesNames.notifications }).catch(navigationErrorHandler);
      }
    },
    isPaymentMethodsTabVisible() {
      return this.userData.getPermissions().canChangeBusinessProfilePaymentMethod();
    },
  },
};
</script>
