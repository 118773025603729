<template>
  <ul class="RideshareLocationLineComponent position-relative m-0">
    <li
      v-for="(rideshareStop, index) in rideshareStops"
      :key="index"
      :data-test-id="`rideshare-location-line-wrapper-${index}`"
      class="rideshare-location-line-wrapper d-flex flex-wrap"
    >
      <div
        class="location-info w-100"
        data-test-id="location-info"
      >
        <span
          class="address"
          data-test-id="address-content"
        >
          <strong>{{ rideshareStop.address }}</strong>
        </span>
      </div>

      <div
        :class="{
          'rideshare-date': index < size(rideshareStops) - 1
        }"
        class="emobg-body-small emobg-color-light-ink w-100"
      >
        <span
          v-if="rideshareStop.datetime"
          data-test-id="start_date-content"
        >
          {{ formatDate(rideshareStop.datetime, datetimeFormat) }}
        </span>
      </div>
    </li>
  </ul>
</template>
<script>
import size from 'lodash/size';
import { formatDate } from '@emobg/web-utils';

import { DATETIME_FORMATS } from '@/constants/datetimeFormats';

export default {
  name: 'RideshareLocationLineComponent',

  props: {
    rideshareStops: {
      type: Array,
      required: true,
    },
  },

  created() {
    this.datetimeFormat = DATETIME_FORMATS.shortPrettyDatetime;
  },
  methods: {
    size,
    formatDate,
  },
};

</script>
