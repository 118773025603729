<template>
  <GenericCardWrapperComponent
    data-test-id="rideshare-card"
    class="CarpoolingRideshareComponent w-100"
    :open="areTripDetailsOpen"
  >
    <template #header>
      <div
        data-test-id="header-container"
        class="d-flex w-100 px-4 py-4 align-items-center emobg-border-bottom-1 emobg-border-color-ground"
      >
        <div
          class="emobg-font-large emobg-font-weight-bold"
          data-test-id="title-content"
        >
          {{ rideshare.vehicle.brand }} {{ rideshare.vehicle.model }}
        </div>
        <div class="ml-auto">
          <ui-button
            v-bind="buttonData"
            data-test-id="action-button"
            :loading="isLoading"
            @clickbutton="$emit(buttonData.action)"
          >
            {{ buttonData.text }}
          </ui-button>
        </div>
      </div>
    </template>
    <template #body>
      <div
        class="d-flex py-4 px-4"
        data-test-id="body-container"
      >
        <div class="d-flex flex-column w-100">
          <div class="d-flex">
            <RideshareLocationLineComponent
              :rideshare-stops="rideshareStops"
              class="w-50"
            />
            <div class="d-flex flex-column w-50 align-items-baseline">
              <div class="pb-2">
                <span class="emobg-font-weight-bold">
                  {{ $t('CarpoolingBookingListView.CarpoolingRideshareComponent.driver_label') }}:
                </span>
                <span
                  v-cs-mask
                  class="emobg-color-light-ink"
                  data-test-id="driver_name-content"
                >
                  {{ rideshare.driverFirstName }} {{ rideshare.driverLastName }}
                </span>
              </div>
              <div class="pb-2">
                <span class="emobg-font-weight-bold">
                  {{ $t('CarpoolingBookingListView.CarpoolingRideshareComponent.avalaible_seats_label') }}:
                </span>
                <span
                  class="emobg-color-light-ink"
                  data-test-id="available_seats-content"
                >
                  {{ rideshare.availableSeats }}
                </span>
              </div>
            </div>
          </div>
          <ui-button
            v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
            :size="SIZES.small"
            class="p-0 m-0 ml-auto d-flex"
            data-test-id="view_trip_details-button"
            @clickbutton="() => areTripDetailsOpen = !areTripDetailsOpen"
          >
            {{ $t('CarpoolingBookingListView.CarpoolingRideshareComponent.view_trip_details_button') }}
            <ui-icon
              :icon="areTripDetailsOpen ? ICONS.arrowDown : ICONS.arrowUp"
              :color="COLORS.primary"
              :size="ICONS_SIZES.small"
              class="d-block ml-3"
            />
          </ui-button>
        </div>
      </div>
    </template>
    <template #collapsable-content>
      <div class="d-flex flex-column pt-0 pb-4 px-4">
        <SpecificationsComponent v-bind="rideshare.vehicle" />
        <ExtrasComponent
          class="mt-4"
          :extras="rideshare.vehicle.equipment"
        />
      </div>
    </template>
  </GenericCardWrapperComponent>
</template>
<script>
import { CsMask } from '@emobg/vue-base';
import GenericCardWrapperComponent from '@/components/GenericCardWrapper/GenericCardWrapperComponent';
import SpecificationsComponent from '@/components/VehicleCard/components/Specifications/SpecificationsComponent';
import ExtrasComponent from '@/components/VehicleCard/components/Extras/ExtrasComponent';
import { useTheme } from '@/composable/Theme/useTheme';

import RideshareLocationLineComponent from './components/RideshareLocationLineComponent';
import {
  buttonAction, buttonColor, buttonDisabled, buttonText,
} from './helpers/helpers';

export default {
  name: 'CarpoolingRideshareComponent',

  components: {
    GenericCardWrapperComponent,
    SpecificationsComponent,
    ExtrasComponent,
    RideshareLocationLineComponent,
  },

  directives: {
    CsMask,
  },

  props: {
    rideshare: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },

  data() {
    return {
      areTripDetailsOpen: false,
    };
  },

  computed: {
    buttonData() {
      return {
        ...this.fetchButtonSpecs({ buttonType: buttonColor(this.rideshare.isDriver, this.rideshare.joined) }),
        action: buttonAction(this.rideshare.isDriver, this.rideshare.joined),
        text: buttonText(this.$t, this.rideshare.isDriver, this.rideshare.joined),
        disabled: this.isDisabled || buttonDisabled(this.rideshare.availableSeats, this.rideshare.joined),
      };
    },
  },

  created() {
    this.rideshareStops = [
      { address: this.rideshare.originAddress, datetime: this.rideshare.start },
      { address: this.rideshare.destinationAddress },
    ];
  },
};
</script>
