<template>
  <section class="ShowEmployeePortalView">
    <header>
      <nav class="Navbar Navbar--shadow Navbar--fixed">
        <div class="Navbar__section Navbar__section--flex" />

        <div class="Navbar__section Navbar__section--auto">
          <RouterLink :to="{ name: routes.home }">
            <Logo class="d-flex" />
          </RouterLink>
        </div>

        <div class="Navbar__section Navbar__section--end" />
      </nav>
    </header>

    <div class="LayoutPage page mx-auto">
      <article class="py-6">
        <header>
          <main v-html="compiledDescription" />
        </header>
      </article>
    </div>
  </section>
</template>

<script>
import Marked from 'marked';
import LoaderMixin from '@/mixins/Loader';
import BookingRoutesNames from '@Bookings/router/routes-names';
import { employeePolicy } from '@/stores/Company/CompanyMapper';
import Logo from '@/components/Logo/Logo';

export default {
  name: 'ShowEmployeePolicy',

  components: {
    Logo,
  },

  mixins: [
    LoaderMixin,
  ],

  data() {
    return {
      routes: {
        ...BookingRoutesNames,
      },
    };
  },

  computed: {
    employeePolicy,

    policy() {
      return this.employeePolicy || '';
    },

    compiledDescription() {
      return Marked(this.policy, { sanitize: true, breaks: true });
    },
  },

  mounted() {
    this.toggleLoaderStatus(false);
  },
};

</script>
