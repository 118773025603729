<template>
  <MainViewLayout>
    <template #main-content>
      <div class="phLayoutSettings LayoutPage">
        <div class="CarpoolingBookingsList w-100 align-items-center d-flex flex-column pt-4">
          <div
            class="d-flex flex-column"
            data-test-id="header"
          >
            <h2 class="mt-4">
              {{ $t('CarpoolingBookingListView.title') }}
            </h2>
            <h3>
              {{ $t('CarpoolingBookingListView.subtitle') }}
            </h3>
            <p>
              <CarpoolingBookingsListHeader
                :rideshares-locations="ridesharesLocations"
                :user-uuid="getUserData.uuid"
                @update:sort-option="newSortOption => currentSortOption = newSortOption"
                @update:filters="newFiltersValues => currentFiltersValues = newFiltersValues"
              />
            </p>
            <ui-loader v-if="isLoading" />
            <div v-else>
              <div v-if="size(refinedRideshares(currentFiltersValues, currentSortOption))">
                <CarpoolingRideshareComponent
                  v-for="rideshare in refinedRideshares(currentFiltersValues, currentSortOption)"
                  :key="rideshare.uuid"
                  :rideshare="rideshare"
                  :is-disabled="isButtonLoading && rideshare.uuid !== actualRideshare"
                  :is-loading="isButtonLoading && rideshare.uuid === actualRideshare"
                  @cancel-rideshare="() => modal = openCancelModal(rideshare)"
                  @leave-rideshare="() => modal = openLeaveModal(rideshare)"
                  @join-rideshare="() => joinRideShareSubmit(rideshare.uuid)"
                />
              </div>
              <EmptyStateComponent
                v-else
                :src="emptyStateImage"
                :title="$t('carpooling.no_carpooling')"
                :description="$t('carpooling.no_carpooling_description')"
                :action="{
                  to: { name: routes.home },
                  text: $t('empty_list.link'),
                }"
                has-background
              />
            </div>
          </div>
        </div>
      </div>

      <FeedbackModalComponent
        v-model="modal.isOpen"
        v-bind="modal"
      />
    </template>
  </MainViewLayout>
</template>
<script>
import moment from 'moment';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import size from 'lodash/size';

import { mapActions, mapGetters } from 'vuex';

import {
  DELAY,
  formatDate,
  LOG_TYPE,
  logger,
} from '@emobg/web-utils';

import { nameSpace as UserDataNameSpace } from '@/vue/stores/UserData/UserDataStore';
import { nameSpace as ProfileNamespace } from '@Profile/store';
import { NAMESPACE as CarpoolingModuleNameSpace } from '@/domains/Carpooling/store/CarpoolingModule';

import MainViewLayout from '@/templates/MainLayout/MainLayout';
import CarpoolingRideshareComponent from '@/domains/Carpooling/components/CarpoolingRideshareComponent/CarpoolingRideshareComponent';
import CarpoolingBookingsListHeader from '@/domains/Carpooling/components/CarpoolingBookingsListHeader/CarpoolingBookingsListHeader';
import EmptyStateComponent from '@/components/EmptyState/EmptyStateComponent';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';

import EventHandlerMixin from '@/mixins/EventHandler';

import { emptyCarpoolingTrips } from '@/utils/publicImages';
import { genericConfirmArgs, genericErrorArgs } from '@/constants/defaultModalArgs';
import { DATETIME_FORMATS } from '@/constants/datetimeFormats';
import BookingRoutesNames from '@Bookings/router/routes-names';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { setLoaderStatus } from '@Shared/store/Loader/LoaderMapper';
import { cancelRideshareArgs, leaveRideshareArgs } from './constants/modalArgs';

export default {
  name: 'CarpoolingBookingsListView',
  components: {
    MainViewLayout,
    CarpoolingRideshareComponent,
    CarpoolingBookingsListHeader,
    EmptyStateComponent,
    FeedbackModalComponent,
  },

  mixins: [
    EventHandlerMixin,
  ],
  setup() {
    const { notifySuccess } = useNotifications();
    return {
      notifySuccess,
    };
  },
  data() {
    return {
      isLoading: false,
      isButtonLoading: false,
      currentSortOption: {
        field: 'start',
        transformer: moment,
      },
      currentFiltersValues: {},
      modal: {
        isOpen: false,
      },
      actualRideshare: undefined,
    };
  },
  computed: {
    ...mapGetters(CarpoolingModuleNameSpace, ['ridesharesLocations', 'refinedRideshares']),
    ...mapGetters(UserDataNameSpace, ['companyAllowsCarpooling', 'getUserData']),
    ...mapGetters(ProfileNamespace, ['isBusinessProfileActive', 'getSelectedProfile']),
    canAccessCarpoolingView() {
      return this.isBusinessProfileActive && this.companyAllowsCarpooling;
    },
  },

  watch: {
    getSelectedProfile(newUuid) {
      if (newUuid) {
        this.validateCarpoolingAccess();
      }
    },
  },

  async created() {
    this.startDateFormat = DATETIME_FORMATS.shortPrettyDatetime;
    this.validateCarpoolingAccess();
    this.emptyStateImage = emptyCarpoolingTrips;
    this.routes = BookingRoutesNames;

    await this.fetchRideShares();
    this.openLeaveModal = rideshare => ({
      ...genericConfirmArgs(this.$t),
      ...leaveRideshareArgs(this.$t, formatDate(rideshare.start, this.startDateFormat)),
      secondaryCallToAction: () => { this.modal.isOpen = false; },
      primaryCallToAction: async () => { await this.submitModalAction(this.leaveRideshare, 'leave_rideshare', rideshare.uuid); },
      isOpen: true,
    });

    this.openCancelModal = rideshare => ({
      ...genericConfirmArgs(this.$t),
      ...cancelRideshareArgs(this.$t, formatDate(rideshare.start, this.startDateFormat)),
      secondaryCallToAction: () => { this.modal.isOpen = false; },
      primaryCallToAction: async () => { await this.submitModalAction(this.cancelRideshare, 'cancel_rideshare', rideshare.uuid); },
      primaryButtonType: this.THEME_BUTTON_TYPES.DESTRUCTIVE,
      isOpen: true,
    });
    this.setLoaderStatus(false);
  },

  methods: {
    setLoaderStatus,
    size,
    ...mapActions(CarpoolingModuleNameSpace, [
      'getRideshares',
      'joinRideshare',
      'cancelRideshare',
      'leaveRideshare',
      'clearError',
    ]),
    validateCarpoolingAccess() {
      if (!this.canAccessCarpoolingView) {
        this.$router.push({ name: BookingRoutesNames.home });
      }
    },
    displayErrorModal(error, action) {
      const buttonAction = isFunction(action)
        ? action
        : () => {
          this.modal.isOpen = false;
          this.clearError();
        };

      logger.message(get(error, 'message'), LOG_TYPE.error);
      this.modal = {
        ...genericErrorArgs(this.$t),
        primaryCallToAction: buttonAction,
        isOpen: true,
      };
    },
    async fetchRideShares() {
      try {
        this.isLoading = true;
        await this.getRideshares();
      } catch (error) {
        this.displayErrorModal(error, () => { this.$router.push({ name: BookingRoutesNames.home }); });
      } finally {
        this.isLoading = false;
      }
    },
    async joinRideShareSubmit(rideshareUuid) {
      try {
        this.isButtonLoading = true;
        this.actualRideshare = rideshareUuid;
        await this.joinRideshare(rideshareUuid);
        this.notifySuccess({
          text: this.$t('CarpoolingBookingListView.notifications.join_rideshare.success'),
          delayToClose: DELAY.extraLong,
        });
        await this.fetchRideShares();
      } catch (error) {
        this.displayErrorModal(error);
      } finally {
        this.isButtonLoading = false;
      }
      this.actualRideshare = undefined;
    },
    async submitModalAction(action, actionName, params) {
      try {
        this.modal = {
          ...this.modal,
          primaryCallToActionLoading: true,
        };
        await action(params);
        this.modal = {
          ...this.modal,
          primaryCallToActionLoading: false,
          isOpen: false,
        };
        this.notifySuccess({
          text: this.$t(`CarpoolingBookingListView.notifications.${actionName}.success`),
          delayToClose: DELAY.extraLong,
        });
        await this.fetchRideShares();
      } catch (error) {
        this.displayErrorModal(error);
      }
    },
  },
};
</script>
