<template>
  <div
    data-test-id="carpooling_list_header-component"
    class="d-flex flex-column"
  >
    <div class="d-flex w-100 my-4">
      <ui-button
        :color="COLORS.primary"
        :face="FACES.outline"
        data-test-id="open_filters-button"
        @clickbutton="() => areFiltersOpen = !areFiltersOpen"
      >
        <div class="align-items-center">
          <ui-icon
            :icon="ICONS.settingsSlider"
            :size="ICONS_SIZES.small"
            class="mr-1"
          />
          {{
            areFiltersOpen
              ? $t('CarpoolingBookingListView.CarpoolingBookingsListHeader.filter_collapse_button.close_text')
              : $t('CarpoolingBookingListView.CarpoolingBookingsListHeader.filter_collapse_button.open_text')
          }}
        </div>
      </ui-button>

      <ui-dropdown
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
        arrow
        data-test-id="sort_by-button"
        class="ml-auto"
      >
        <span
          slot="trigger"
          class="d-flex justify-content-between px-3"
        >
          {{ $t('CarpoolingBookingListView.sort_by_label') }}
        </span>
        <template v-if="sortOptions && sortOptions.length">
          <ui-dropdown-actions
            slot="content"
            :actions.prop="sortOptions"
          />
        </template>
      </ui-dropdown>
    </div>
    <ui-collapse :open="areFiltersOpen">
      <div
        data-test-id="filters"
        class="d-flex flex-column py-4 w-100"
      >
        <div class="d-flex w-100">
          <ui-select-multiple
            :values.prop="filters.outgoingLocations"
            :options.prop="ridesharesLocations"
            :label="$t('CarpoolingBookingListView.CarpoolingBookingsListHeader.filters.outgoing_locations_select.label')"
            :placeholder="$t('CarpoolingBookingListView.CarpoolingBookingsListHeader.filters.outgoing_locations_select.label')"
            name="outgoing-location"
            data-test-id="outgoing_location-select"
            class="w-50"
            @selectoptions="({ detail }) => filters.outgoingLocations = detail"
          />
          <ui-select-multiple
            :values.prop="filters.returnLocations"
            :options="ridesharesLocations"
            :label="$t('CarpoolingBookingListView.CarpoolingBookingsListHeader.filters.return_locations_select.label')"
            :placeholder="$t('CarpoolingBookingListView.CarpoolingBookingsListHeader.filters.return_locations_select.label')"
            name="return-location"
            data-test-id="return_location-select"
            class="w-50 ml-4"
            @selectoptions="({ detail }) => filters.returnLocations = detail"
          />
        </div>

        <div class="d-flex w-100 pt-4">
          <div class="d-flex flex-column w-50">
            <span class="emobg-body-medium emobg-font-weight-bold">
              {{ $t('CarpoolingBookingListView.CarpoolingBookingsListHeader.filters.owner_checkbox.title') }}
            </span>
            <ui-checkbox
              :value="filters.ownedByUser"
              :caption="$t('CarpoolingBookingListView.CarpoolingBookingsListHeader.filters.owner_checkbox.label')"
              data-test-id="owned_by_user-checkbox"
              @changevalue="({ detail }) => filters.ownedByUser = detail"
            />
          </div>
          <div class="d-flex flex-column w-50 ml-4">
            <span class="emobg-body-medium emobg-font-weight-bold">
              {{ $t('CarpoolingBookingListView.CarpoolingBookingsListHeader.filters.available_seats_checkbox.title') }}
            </span>
            <ui-checkbox
              :value="filters.availableSeats"
              :caption="$t('CarpoolingBookingListView.CarpoolingBookingsListHeader.filters.available_seats_checkbox.label')"
              placeholder="test"
              data-test-id="available_trips_only-checkbox"
              @changevalue="({ detail }) => filters.availableSeats = detail"
            />
          </div>
        </div>
      </div>
    </ui-collapse>
  </div>
</template>
<script>
import moment from 'moment';
import size from 'lodash/size';
import includes from 'lodash/includes';
import get from 'lodash/get';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'CarpoolingBookingsListHeader',

  props: {
    ridesharesLocations: {
      type: Array,
      default: () => ([]),
    },
    userUuid: {
      type: String,
      required: true,
    },
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs };
  },

  data() {
    return {
      areFiltersOpen: true,
      filters: {
        ownedByUser: false,
        availableSeats: false,
        outgoingLocations: [],
        returnLocations: [],
      },
    };
  },

  watch: {
    filters: {
      handler() {
        this.updateFilters();
      },
      deep: true,
    },
  },

  created() {
    this.sortOptions = [{
      label: this.$t('CarpoolingBookingListView.CarpoolingBookingsListHeader.sorting_options.by_trip_date'),
      action: () => this.$emit('update:sort-option', { field: 'start', transformer: moment }),
      key: 'by_date',
    },
    {
      label: this.$t('CarpoolingBookingListView.CarpoolingBookingsListHeader.sorting_options.by_station'),
      action: () => this.$emit('update:sort-option', { field: 'location.name' }),
      key: 'by_location',
    }];
  },

  methods: {
    updateFilters() {
      const currentFiltersValues = [
        ...(this.filters.ownedByUser ? [rideshare => rideshare.driverUuid === this.userUuid] : []),
        ...(this.filters.availableSeats ? [rideshare => rideshare.availableSeats > 0] : []),
        ...(size(this.filters.outgoingLocations) ? [rideshare => includes(this.filters.outgoingLocations, get(rideshare, 'originAddress'))] : []),
        ...(size(this.filters.returnLocations) ? [rideshare => includes(this.filters.returnLocations, get(rideshare, 'destinationAddress'))] : []),
      ];
      this.$emit('update:filters', currentFiltersValues);
    },
  },

};
</script>
